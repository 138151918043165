import React from 'react';
import PropTypes from 'prop-types';
import HereDomMarker from 'shared/hereMap/HereDomMarker';

function HerePolygonMarker({ position, index, hideOnDrag, draggable, resizable, onChange, onClick, middlePoint, type, cursorStyle, icon }) {
  const basicColor = type === 'polygonMarker' ? 'lightblue' : 'white';
  const pointIcon = '<svg width="12" height="12" version="1.1" xmlns="http://www.w3.org/2000/svg">' +
    '<style>.small {font-size: 10px;}</style>' +
    '<rect width="12" height="12" fill="'+(draggable||resizable ?basicColor:'gray')+'" stroke="black" stroke-width="1"/>' +
    '<text x="6" y="10" class="small" text-anchor="middle">'+index+'</text>'+
    '</svg>';
  const middlePointIcon = '<svg width="12" height="12" version="1.1" xmlns="http://www.w3.org/2000/svg">' +
    '<circle cx="6" cy="6" r="5" fill="'+basicColor+'" stroke="black" stroke-width="1"/>' +
    '</svg>';

  icon = icon || new window.H.map.Icon(
    middlePoint ? middlePointIcon : pointIcon, {anchor: {x: 6, y: 6}}
  );
  const data = {hideOnDrag};
  
  return <HereDomMarker 
    latLng={position} 
    options={{ icon, data }} 
    draggable={draggable} 
    resizable={resizable} 
    onChange={onChange} 
    onClick={onClick}
    cursorStyle={cursorStyle}
  />;
}

HerePolygonMarker.propTypes = {
  position: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
};

export default HerePolygonMarker;
