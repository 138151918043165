import 'leaflet-contextmenu';
import 'leaflet-contextmenu/dist/leaflet.contextmenu.css';
import 'leaflet-polylineoffset/leaflet.polylineoffset';
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withLeaflet } from 'react-leaflet';
import { isConditionsSatisfied } from '../../utils';
import mapCenterConfigs from './mapCenterConfigs';
import showInMap from './showInMap';
import { setIsOpenInShown } from 'state/ui/reducer';

class ContextMenu extends React.Component {
  componentDidMount() {
    this.renderContextMenu();
  }

  componentDidUpdate() {
    this.renderContextMenu();
  }

  renderContextMenu() {
    const {
      selectedTab,
      tabIndex,
      fields,
      leaflet: { map },
      refClient: { formatterPlugin, mapPlugin },
      addTab,
      setNotification,
      setFields,
      settings,
      setIsOpenInShown,
      bounds: { zoom },
      request
    } = this.props;
    if (selectedTab !== tabIndex) {
      return null;
    }
    const { items } = this.props.options;
    const { contextmenu } = map;
    contextmenu.removeAllItems();
    [...items, mapCenterConfigs, showInMap].forEach((item) => {
      const { type, options = {} } = item;
      if (!isConditionsSatisfied(this.props, options.conditions)) {
        return;
      }

      const contextMenuItem = mapPlugin.get(type);
      const { text, callback } = contextMenuItem({
        options,
        fields,
        formatterPlugin,
        addTab,
        setFields,
        setNotification,
        settings,
        setIsOpenInShown,
        request
      });
      contextmenu.addItem({
        text,
        callback: (e) => {
          try {
            e.latlng = e.relatedTarget.getLatLng();
          } catch (e) {
            // skip exception
          }
          try {
            e.latlng = e.latlng.wrap();
          } catch (e) {
            // skip exception
          }
          const originalEvent = e;
          const { lat, lng } = e.latlng;
          const { x, y } = e.layerPoint;
          return callback({
            latLng: { lat, lng },
            xy: { x, y },
            xyToLatLng: map.layerPointToLatLng.bind(map),
            originalEvent,
            zoom,
          });
        },
      });
    });
    return null;
  }

  render = () => null;
}

const mapDispatchToProps = {
  setIsOpenInShown
};

function mapStateToProps() {
  return {};
}

ContextMenu.propTypes = {
  leaflet: PropTypes.object.isRequired,
  options: PropTypes.object.isRequired,
  selectedTab: PropTypes.number.isRequired,
  tabIndex: PropTypes.number.isRequired,
  fields: PropTypes.object.isRequired,
  refClient: PropTypes.object.isRequired,
  addTab: PropTypes.func.isRequired,
  setFields: PropTypes.func.isRequired,
  setNotification: PropTypes.func.isRequired,
  settings: PropTypes.object.isRequired,
};

export default withLeaflet(connect(mapStateToProps, mapDispatchToProps)(ContextMenu));

