import L from 'leaflet';
import { divIcon } from 'leaflet';

const fontSize = 12;

export function createCanvas(color, text, type) {
  var canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  
  if (type === 'text') {
    ctx.font = 'bold '+fontSize+'px Arial';

    const width = ctx.measureText(text).width;
    const height = 24;

    canvas.width = width;
    canvas.height = height;
    ctx.font = 'bold '+fontSize+'px Arial';

    ctx.fillStyle = color;
    ctx.textAlign = 'center'; 
    ctx.fillText(text, width/2, height);

    return {canvas, size:{ width, height }}
  } else {
    const width = 14;
    const height = 14;
    const radius = 5;

    canvas.width = width;
    canvas.height = height;

    ctx.beginPath();
    ctx.arc(width/2, 7, radius, 0, 2 * Math.PI, false);
    ctx.fillStyle = 'white';
    ctx.fill();
    ctx.lineWidth = 3;
    ctx.strokeStyle = '#000';
    ctx.stroke();
    ctx.closePath();

    return { canvas, size:{ width, height } }
  }
  
};

export function createIcon(canvas, {width}) {
  const icon = new window.H.map.Icon(canvas, ({
    'anchor': {
      'x': width/2,
      'y': 7
    }
  }));

  return icon;
}

const canvas = document.createElement('canvas');
const context = canvas.getContext('2d');
context.font = 'bold '+fontSize+'px Arial';

export function createLeafletIcon(color, text, type) {
  if (type === 'text') {
    // Estimate the width of the text
    const width = context.measureText(text).width + 10;
    const height = 32;
    const svg = `<svg width="${width}" height="${height}" xmlns="http://www.w3.org/2000/svg"><text x="${width/2}" y="18" font-family="Arial" font-weight="bold" font-size="12px" fill="${color}" text-anchor="middle">${text}</text></svg>`;
    const iconUrl = `data:image/svg+xml;base64,${btoa(svg)}`;
    const icon = new L.Icon({
      iconUrl,
      iconRetinaUrl: iconUrl,
      iconAnchor: [width/2, 0],
      iconSize: [width, height],
    });
  
    return icon;
  } else {
    return divIcon({
      html: `<div class="rf-distance-measurement__marker">${text}</div>`,
    });
  }
}