import React from 'react';
import PropTypes from 'prop-types';
import { TILE_LIBRARY_TYPES } from '../../../state/map/tiles/constants';
import { createCanvas, createIcon, createLeafletIcon } from './icons';
import { isEqual } from 'lodash';
import { latLng } from 'leaflet';
import './distanceMeasurement.scss';

function DistanceMeasurement(MarkerComponent, LinesComponent, MapType) {
  return class extends React.Component {
    static propTypes = {
      options: PropTypes.object,
      fields: PropTypes.object,
      result: PropTypes.object,
      colorPalette: PropTypes.object,
      bounds: PropTypes.object,
      tabIndex: PropTypes.number.isRequired,
      selectedTab: PropTypes.number.isRequired,
      setFields: PropTypes.func.isRequired,
    };

    distanceMeasurement = (evt) => {
      const { currentPointer, latlng } = evt;
      const {
        fields: { ruler },
        setFields,
        selectedTab,
        tabIndex,
        map
      } = this.props;

      if ( selectedTab === tabIndex && evt.originalEvent.button === 0 ) {
        if ( currentPointer ) {
          const coord = map.screenToGeo(currentPointer.viewportX, currentPointer.viewportY);
          ruler.push(coord);
        } else {
          ruler.push(latlng);
        }
        setFields({ruler});
      }
    }

    startMeasurement = () => {
      const {
        map
      } = this.props;

      if ( MapType === TILE_LIBRARY_TYPES.JSLA ) {
        map.addEventListener('tap', this.distanceMeasurement);
      } else if ( MapType === TILE_LIBRARY_TYPES.LEAFLET ) {
        map.on('click', this.distanceMeasurement);
      }
    }

    stopMeasurement = () => {
      const {
        map
      } = this.props;

      if ( MapType === TILE_LIBRARY_TYPES.JSLA ) {
        map.removeEventListener('tap', this.distanceMeasurement);
      } else if ( MapType === TILE_LIBRARY_TYPES.LEAFLET ) {
        map.off('click', this.distanceMeasurement);
      }
    }

    componentDidMount = () => {
      const {
        fields: { ruler },
        map
      } = this.props;
      
      if ( !!ruler && map && (map.on || map.addEventListener) ) {
        this.startMeasurement();
      }
    }

    componentDidUpdate = (prevProps) => {
      const {
        fields: { ruler },
        map
      } = this.props;

      if ( (!prevProps.fields.ruler && !!ruler) 
        || (!isEqual(prevProps.map, map) && !!ruler) 
      ) {
        this.startMeasurement();
      } else if ( !!prevProps.fields.ruler && !ruler ) {
        this.stopMeasurement();
      }
    }

    componentWillUnmount = () => {
      const {
        fields: { ruler }
      } = this.props;

      if ( !!ruler ) {
        this.stopMeasurement();
      }
    }
  
    onClickPoint = (index) => {
      const { 
        fields: { ruler },
        setFields,
      } = this.props;

      setFields({ruler: [...ruler.slice(0, index), ...ruler.slice(index+1)]});
    }

    onChangePoint = (index, evt) => {
      const { 
        fields: { ruler },
        setFields,
      } = this.props;

      ruler[index] = evt;
      setFields({ruler});
    };

    beautify = (distance) => {
      if (distance === 0) {
        return distance;
      } else if ( distance < 1000 ) {
        return (distance.toFixed(2).replace('.', ',') + ' m');
      } else {
        const formattedNumber = (distance/1000).toFixed(2)
        .replace('.', ',')
        .replace(/\B(?=(\d{3})+(?!\d))/g, '.');
        return (formattedNumber + ' km');
      }
    }

    render() {
      let {
        colorPalette,
        tabIndex,
        selectedTab,
        fields: { ruler }
      } = this.props;
      
      let distances = [0];
      const texts = (ruler||[]).map((point, index) => {
        let icon;
        if ( MapType === TILE_LIBRARY_TYPES.JSLA ) {
          if ( index>0 ) {
            const A = new window.H.geo.Point(point.lat, point.lng);
            const B = new window.H.geo.Point(ruler[index-1].lat, ruler[index-1].lng);
            distances.push(A.distance(B));
          }
          const {canvas, size} = createCanvas(colorPalette.primary, this.beautify(distances.reduce((partialSum, a) => partialSum + a, 0)), 'text');
          icon = createIcon(canvas, size);
        } else if ( MapType === TILE_LIBRARY_TYPES.LEAFLET ) {          
          if ( index>0 ) {
            const A = latLng(point.lat, point.lng);
            const B = latLng(ruler[index-1].lat, ruler[index-1].lng);
            distances.push(A.distanceTo(B));
          }
          icon = createLeafletIcon(colorPalette.primary, this.beautify(distances.reduce((partialSum, a) => partialSum + a, 0)), 'text');
        }
        return <MarkerComponent 
          key={index+'texts'} 
          icon={icon}
          hideOnDrag={true}
          position={point} 
          index={index+1} 
          geometryIndex={0}
        />
      })
      const points = (ruler||[]).map((point, index) => {
        let icon;
        if ( MapType === TILE_LIBRARY_TYPES.JSLA ) {
          const {canvas, size} = createCanvas(colorPalette.primary, index);
          icon = createIcon(canvas, size);
        } else if ( MapType === TILE_LIBRARY_TYPES.LEAFLET ) {
          icon = createLeafletIcon(colorPalette.primary, index);
        }
        return <MarkerComponent 
          key={index} 
          icon={icon}
          hideOnDrag={true}
          position={point} 
          index={index+1} 
          geometryIndex={0}
          draggable={tabIndex === selectedTab ? true : false} 
          resizable={tabIndex === selectedTab ? true : false}
          onChange={tabIndex === selectedTab ? this.onChangePoint.bind(this, index) : null}
          onClick={tabIndex === selectedTab ? this.onClickPoint.bind(this, index) : null}
        />
      })

      const polyline = (ruler||[]).map(({lat, lng}) => {
        return {
          lat: lat.toFixed(6),
          lng: lng.toFixed(6)
        }
      });
      return <>
        <LinesComponent
          positions={polyline}
          strokeColor={colorPalette.primary}
          zIndex={400}
          lineWidth={2}
          onChange={()=>{}}
        />
        {texts}
        {points}
      </>;
    }
  };
}

export default DistanceMeasurement;
